export default class Tabs {
  constructor() {
    this.triggers = document.querySelectorAll("[data-triggers] a");
    this.tabs = document.querySelectorAll("div[data-tab]");
    //this.next = document.querySelector("a[data-dir='tab-next']");
    //this.prev = document.querySelector("a[data-dir='tab-prev']");
    //this.bar = document.querySelector(".tabs .progress");

    this.listeners();
  }

  listeners() {
    [...this.triggers].forEach(el => {
      el.addEventListener("click", this.switchTab, false);
    });

    //this.next.addEventListener("click", this.nextTab, false);
    //this.prev.addEventListener("click", this.prevTab, false);
  }

  static progress(el) {}

  switchTab(el) {
    el.preventDefault();
    console.log("click");

    Tabs.removeActive("[data-tabs] [data-active]");

    let tabId = el.currentTarget.dataset.tab;
    let tab = document.querySelector(`.info[data-tab=${tabId}]`);

    el.currentTarget.dataset.active = true;
    tab.dataset.active = true;
  }

  static removeActive(selector) {
    [...document.querySelectorAll(selector)].forEach(
      el => delete el.dataset.active
    );
  }

  nextTab(el) {
    el.preventDefault();
    Tabs.witchTab();
  }

  prevTab(el) {
    el.preventDefault();
    Tabs.witchTab();
  }
}
