
import Modal from "./modules/modal.js"; //quite
import Tabs from "./modules/tabs.js"; //quite
import objectFitImages from "./extern/ofi.min.js"; //quite

var app = new Vue({
    el: "#app",
    data: {
        name: ""
    },
    mounted() {
        if (localStorage.name) {
            this.name = localStorage.name;
        }

        // Check if gform is defined before using it
        if (typeof gform !== 'undefined') {
            gform.initializeOnLoaded(function() {
                gformInitSpinner(1, 'https://bampsverzekeringen.be/wp-content/plugins/gravityforms/images/spinner.svg', true);

                jQuery('#gform_ajax_frame_1').on('load', function() {
                    var contents = jQuery(this).contents().find('*').html();
                    var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;
                    if (!is_postback) return;

                    var form_content = jQuery(this).contents().find('#gform_wrapper_1');
                    var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_1').length > 0;
                    var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
                    var is_form = form_content.length > 0 && !is_redirect && !is_confirmation;

                    if (is_form) {
                        jQuery('#gform_wrapper_1').html(form_content.html());
                        if (form_content.hasClass('gform_validation_error')) {
                            jQuery('#gform_wrapper_1').addClass('gform_validation_error');
                        } else {
                            jQuery('#gform_wrapper_1').removeClass('gform_validation_error');
                        }
                        setTimeout(function() {
                            // delay the scroll by 50 milliseconds to fix a bug in chrome
                        }, 50);

                        if (window['gformInitDatepicker']) {
                            gformInitDatepicker();
                        }
                        if (window['gformInitPriceFields']) {
                            gformInitPriceFields();
                        }

                        var current_page = jQuery('#gform_source_page_number_1').val();
                        gformInitSpinner(1, 'https://bampsverzekeringen.be/wp-content/plugins/gravityforms/images/spinner.svg', true);
                        jQuery(document).trigger('gform_page_loaded', [1, current_page]);
                        window['gf_submitting_1'] = false;
                    } else if (!is_redirect) {
                        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
                        if (!confirmation_content) {
                            confirmation_content = contents;
                        }
                        jQuery('#gform_wrapper_1').replaceWith(confirmation_content);
                        jQuery(document).trigger('gform_confirmation_loaded', [1]);
                        window['gf_submitting_1'] = false;
                        wp.a11y.speak(jQuery('#gform_confirmation_message_1').text());
                    } else {
                        jQuery('#gform_1').append(contents);
                        if (window['gformRedirect']) {
                            gformRedirect();
                        }
                    }
                });
            });
        } else {
            console.error('gform is not defined');
        }
    },
    watch: {
        name(newName) {
            localStorage.name = newName;

            const input = this.$refs.input;

            if (input) {
                if (newName === "") {
                    input.style.width = "auto";
                } else {
                    input.style.width = (newName.length + 1) * 45 + "px";
                }
            }
        }
    }
});

// Touch sniffer
const htmlElement = document.querySelector("html");
if (htmlElement && "ontouchstart" in document.documentElement) {
    htmlElement.classList.add("is-touch");
}

// Hamburger menu
var $body = document.querySelector("body");
var $hamburger = document.querySelector(".site-hamburger");

if ($hamburger) {
    $hamburger.addEventListener("click", function(e) {
        e.stopPropagation();
        $body.classList.toggle("nav-open");
    });
}

$body.addEventListener("click", function() {
    if ($body.classList.contains('nav-open')) {
        $body.classList.remove("nav-open");
    }
});

const achorLinks = document.querySelectorAll('.site-menu a[href^="#"]');
[...achorLinks].forEach(achor => {
    achor.addEventListener('click', closeNav);
});

function closeNav() {
    $body.classList.remove("nav-open");
}

const $toTop = document.querySelector(".backtotop");
if ($toTop) {
    window.addEventListener("scroll", function(e) {
        let top = window.pageYOffset;
        top > 100
            ? $body.classList.add("is-scroll")
            : $body.classList.remove("is-scroll");
        top > 800 ? $toTop.classList.add("vis") : $toTop.classList.remove("vis");
    });
}

if (document.querySelector('.jobs-swiper')) {
    var jobsSwiper = new Swiper(".jobs-swiper", {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        effect: "fade",
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev"
        }
    });
}

function startingSlide(wrapper, slide = 0) {
    const slides = document.querySelector(`${wrapper} .swiper-wrapper`).childElementCount;
    const start = slide === 0 ? slides : slides - slide;
    console.log(start);
    return start;
}

if (document.querySelector('.history-swiper')) {
    var historySwiper = new Swiper(".history-swiper", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 30,
        initialSlide: startingSlide(".history-swiper", 2),
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev"
        },
        breakpoints: {
            768: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            }
        }
    });
}

if (document.querySelector('.inhouse-swiper')) {
    var inhouseSwiper = new Swiper(".inhouse-swiper", {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        centeredSlides: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: true
        },
        breakpoints: {
            768: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 4
            }
        }
    });
}

jQuery(document).ready(function($) {
    const inputs = ".gfield input:not([type=checkbox]), .gfield textarea, .gfield select";

    $("body").on("focusin focusout", inputs, function(event) {
        if (event.type === "focusin") {
            $(this).parent().parent().addClass("gfield--active");
        } else if (event.type === "focusout") {
            if ($(this).val() === "") {
                $(this).parent().parent().removeClass("gfield--active");
            }
        }
    });

    $(document).on("gform_post_render", function() {
        $(inputs).each(function() {
            let $that = $(this);

            if ($that.val() !== "") {
                $that.parent().parent().addClass("gfield--active");
            }
        });
    });

    objectFitImages();

    new Modal();

    if (document.querySelector("[data-tabs]")) {
        new Tabs();
    }
});

/**
 * New documents setup
 */
jQuery(document).ready(function($) {
    const inputs = ".searchandfilter input:not([type=checkbox]), .searchandfilter textarea, .searchandfilter select";

    $("body").on("focusin focusout", inputs, function(event) {
        if (event.type === "focusin") {
            $(this).parent().parent().addClass("gfield--active");
        } else if (event.type === "focusout") {
            if ($(this).val() === "") {
                $(this).parent().parent().removeClass("gfield--active");
            }
        }
    });
});
